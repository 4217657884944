/**
 * Safely checks if an object has a property without using the prototype directly
 *
 * @param {Object} obj - The object to check
 * @param {string} prop - The property name to check for
 * @return {boolean} - Whether the object has the property
 */
export function hasProperty(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}
