import Errors from './Errors';
import Validator from './Validator';

/**
 * Handle all forms.
 *
 * @author      Ben Carey <bdmc@sinemacula.co.uk>
 * @copyright   2022 Sine Macula Limited.
 */
export default class Form {

    /**
     * Create a new Form instance.
     *
     * @param {string|function}  endpoint
     * @param {object}  attributes
     * @param {string|null}  fields
     * @param {object|null}  callbacks
     * @param {object}  api
     */
    constructor(endpoint,
        attributes,
        fields = null,
        callbacks = null,
        api = window.api,
        options = {}) {
        this.api = api;
        this.endpoint = endpoint;
        this.originalData = {};
        this.rules = {};
        this.mutators = {};
        this.validations = {};
        this.fields = fields;
        this.callbacks = callbacks;
        this.processing = false;
        this.autoResetOnSuccess
            = options.autoReset !== undefined ? options.autoReset : true;

        /*
         * Loop through the supplied attributes and build the data,
         * originalData, and rules
         */
        Object.entries(attributes).forEach(([field, properties]) => {

            /*
             * If properties is just a string then treat it as the value.
             * Otherwise, we treat it as an object and build the data
             * accordingly
             */
            if (typeof properties === 'string') {
                this.originalData[field] = properties;
                this[field] = properties;
            } else {

                // Add the rule if it exists
                this.rules[field] = Object.prototype.hasOwnProperty.call(properties,
                    'rule')
                    ? properties.rule
                    : '';

                // Add the original value
                this.originalData[field] = properties.value;

                // Add the reactive data
                this[field] = properties.value;

                // Register the mutator if it has been defined
                if (
                    Object.prototype.hasOwnProperty.call(properties, 'mutator')
                ) {
                    this.mutators[field] = properties.mutator;
                }

                // Add the validations data
                this.validations[field] = false;
            }
        });

        if (this.rules) {
            this.validator = new Validator(this.rules);
        }

        this.errors = new Errors;
    }

    /**
     * Set the endpoint to be used when submitting the form.
     *
     * @param {string|function} endpoint
     * @returns {void}
     */
    setEndpoint(endpoint) {
        this.endpoint = endpoint;
    }

    /**
     * Fetch all relevant data for the form.
     *
     * @param {boolean}  ignoreEmpty
     * @returns {object}
     */
    data(ignoreEmpty = false) {
        const data = {};

        for (const field in this.originalData) {
            if (
                Object.prototype.hasOwnProperty.call(this.originalData,
                    field)
                && (!ignoreEmpty || ignoreEmpty && this[field] !== '')
            ) {
                data[field] = this[field];
            }
        }

        return data;
    }

    /**
     * Reset the form fields.
     *
     * @param {array|null}  fields
     */
    reset(fields) {
        fields = fields || Object.keys(this.originalData);

        fields.forEach(field => {
            if (
                Object.prototype.hasOwnProperty.call(this.originalData, field)
            ) {
                this[field] = this.originalData[field];
            }
        });

        this.errors.clear(fields);
        this.resetValidation(fields);
    }

    /**
     * Submit the form.
     *
     * @param {boolean}  ignoreEmpty
     * @param {object}  mutators
     */
    submit(ignoreEmpty = false, mutators = {}) {
        if (this.processing) {
            return new Promise(() => {
                throw new Error('Already Processing');
            });
        }

        // Get the data for the form
        const data = this.data(ignoreEmpty);

        // Apply any mutators if they have been set
        if (
            Object.entries(mutators).length !== 0
            && mutators.constructor === Object
        ) {
            Object.entries(mutators).forEach(([key, mutator]) => {
                data[key] = mutator(data[key]);
            });
        }

        // Remove all validation errors
        this.errors.clear();

        this.processing = true;

        /*
         * If rules have been supplied with the form data, then run the
         * validator to ensure the data passes validation
         */
        if (this.validator && this.validate() !== true) {
            return new Promise(() => {
                throw new Error('Validation Errors');
            });
        }

        // If a custom handler has been supplied then run this
        if (typeof this.endpoint === 'function') {
            return new Promise(resolve => {
                this.endpoint();
                resolve();
            });
        }

        return this.api
            .call(this.endpoint, data, this.fields, {}, ignoreEmpty)
            .then(response => {
                this.onSuccess();

                return response.data;
            })
            .catch(error => {
                let errors = {};

                if (
                    !error.response
                    || error.response
                        && !Object.prototype.hasOwnProperty.call(error.response,
                            'status')
                ) {
                    errors.general = [
                        'You appear to have lost your internet connection, please try again.'
                    ];
                } else {

                    /*
                     * Extract the error data from the returned error object.
                     * This data will be used to indicate to the user what went
                     * wrong
                     */
                    switch (error.response.status) {

                    // Validation
                    case 400:
                    case 406:
                    case 408:
                    case 415:
                    case 422:
                    case 429:
                        if (
                            Object.prototype.hasOwnProperty.call(error.response.data.error,
                                'meta')
                        ) {
                            errors = error.response.data.error.meta;

                            // Reset the validations for the invalid fields
                            this.resetValidation(Object.keys(error.response.data.error.meta));
                        } else {
                            errors.general = [
                                error.response.data.error.detail
                            ];
                        }

                        break;

                        // Forbidden
                    case 403:
                        if (
                            Object.prototype.hasOwnProperty.call(error.response.data.error,
                                'meta')
                        ) {
                            errors = error.response.data.error.meta;

                            // Reset the validations for the invalid fields
                            this.resetValidation(Object.keys(error.response.data.error.meta));
                        } else if (error.response.data.error.detail) {
                            errors.general = [
                                error.response.data.error.detail
                            ];
                        } else {
                            errors.general = [
                                'Forbidden! You do not have the necessary privileges to perform this action.'
                            ];
                        }
                        break;

                    default:
                        errors.general = [
                            'There was an error processing your request, please try again.'
                        ];
                        break;
                    }
                }

                // Process the errors to show on the form
                this.onFail(errors);

                /*
                 * We re-throw the error to allow for custom handling on the
                 * form itself, and to ensure the success promise is not run
                 */
                throw error;
            });
    }

    /**
     * Validate the specified fields.
     *
     * @param {null|string|array}  fields
     * @param {null|string|array}  rules
     * @returns {object}
     */
    validate(fields = null, rules = null) {
        let overwrite = false;

        // Ensure the validator has been set
        if (!this.validator) {
            console.warn('No validator has been set');
            return;
        }

        /*
         * Extract the values based on the supplied fields. If no fields have
         * been supplied then extract all of the fields
         */
        if (fields == null) {
            overwrite = true;
            fields = Object.keys(this.originalData);
        } else if (typeof fields === 'string') {
            fields = [ fields ];
        } else if (!Array.isArray(fields)) {
            fields = [];
        }

        /*
         * Extract the values from the form based on the supplied fields
         */
        const values = Object.keys(this.originalData)
            .filter(key => fields.includes(key))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: this[key]
                };
            }, {});

        /*
         * If a mutator has been supplied then run it on the relevant field
         */
        if (this.mutators) {
            Object.entries(this.mutators).forEach(([key, mutator]) => {
                if (values[key]) {
                    values[key] = mutator(values[key]);
                }
            });
        }

        const errors = this.validator.validate(values, rules);

        if (errors !== true) {
            this.onFail(errors, overwrite);
        }

        // Update the validation state
        fields.forEach(field => this.validations[field] = errors === true);

        return errors;
    }

    /**
     * Handle a successful form submission.
     */
    onSuccess() {
        if (
            this.callbacks
            && Object.prototype.hasOwnProperty.call(this.callbacks, 'success')
        ) {
            this.callbacks.success();
        } else if (this.autoResetOnSuccess) {

            // Only reset if enabled
            this.reset();
        }
        this.processing = false;
    }

    /**
     * Handle a failed form submission.
     *
     * @param {object}  errors
     * @param {boolean}  overwrite
     */
    onFail(errors, overwrite = true) {
        if (
            this.callbacks
            && Object.prototype.hasOwnProperty.call(this.callbacks, 'error')
        ) {
            this.callbacks.error(errors);
        } else {
            this.errors.record(errors, overwrite);
        }
        this.processing = false;
    }

    /**
     * Reset the validations for the specific fields.
     *
     * @param {string|array|null} fields
     * @returns {void}
     */
    resetValidation(fields = null) {
        if (fields === null) {
            fields = Object.keys(this.originalData);
        } else if (!Array.isArray(fields)) {
            fields = [ fields ];
        }

        // Clear the errors
        this.errors.clear(fields);

        // Reset the validation booleans
        fields.forEach(field => {
            if (Object.prototype.hasOwnProperty.call(this.validations, field)) {
                this.validations[field] = false;
            }
        });
    }

    /**
     * Determine if the form data has changed.
     *
     * @param {string|array|null} fields
     * @returns {boolean}
     */
    isDirty(fields = null) {
        if (fields === null) {
            fields = Object.keys(this.originalData);
        } else if (!Array.isArray(fields)) {
            fields = [ fields ];
        }

        return fields.some(field => this[field] !== this.originalData[field]);
    }
}
