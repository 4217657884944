<template>
    <GuestLayout
        role="main"
        class="w-screen h-screen bg-[#F8F9FA]"
    >
        <div class="flex h-full w-full bg-[#F8F9FA]">
            <!-- Left Section with Image -->
            <div class="hidden lg:block w-1/2 relative overflow-hidden">
                <!-- Container for image at natural width -->
                <div class="h-full flex items-center">
                    <img
                        class="h-full object-cover"
                        :src="require('static/assets/images/misc/venita-packing-box.jpg')"
                        alt="Space Storage"
                    >
                </div>

                <!-- Gradient overlay that matches image colors and fades right -->
                <div
                    class="absolute inset-0 bg-gradient-to-r from-transparent via-[#f8f8f8]/70 to-white pointer-events-none"
                />
            </div>

            <!-- Right Section with Content -->
            <div class="w-full lg:w-1/2 flex flex-col h-full bg-white relative">
                <!-- Grey cloud at top - mobile only -->
                <img
                    :src="require('static/assets/images/misc/clouds-grey.svg')"
                    alt=""
                    class="lg:hidden absolute top-[3%] right-[10%] w-[100px] sm:w-[140px] md:w-[180px] opacity-80 pointer-events-none"
                >

                <!-- Main Content Area -->
                <div class="flex-1 flex flex-col justify-center px-5 sm:px-8 lg:px-16">
                    <div class="max-w-[420px] mx-auto w-full">
                        <!-- Logo and Header -->
                        <div class="text-center mb-10 lg:mb-16">
                            <img
                                class="w-[200px] sm:w-[240px] lg:w-[280px] mx-auto"
                                :src="require('static/assets/images/brand/logo-blue.svg')"
                                alt="Space Logo"
                            >
                            <h1 class="text-[24px] lg:text-[28px] text-[#000B3E] font-medium leading-tight">
                                Your space, reimagined.
                            </h1>
                        </div>

                        <!-- Main Content -->
                        <div class="space-y-10 lg:space-y-14">
                            <div class="flex flex-col items-center">
                                <h2 class="text-[22px] lg:text-[26px] text-[#000B3E] font-normal mb-4 lg:mb-2">
                                    Ready to make Space?
                                </h2>

                                <button
                                    class="btn-primary w-full h-[52px] text-[16px] mb-4 rounded-full bg-[#E6007E] hover:bg-[#CC006F] text-white font-medium"
                                    @click="onCreateAccountClick"
                                >
                                    Get Started
                                </button>

                                <p
                                    class="w-full sm:w-[90%] lg:w-[80%] text-center text-[11px] sm:text-[12px] text-[#000B3E] font-medium"
                                >
                                    By creating an account, you are agreeing to our
                                    <a
                                        href="https://spacereimagined.io/terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="text-[#E6007E] hover:text-[#CC006F] font-semibold"
                                    >
                                        Terms of Service
                                    </a>
                                </p>
                            </div>

                            <div class="text-center">
                                <p class="text-[14px] lg:text-[15px] text-[#000B3E] font-medium mb-4">
                                    Already have an account?
                                </p>
                                <button
                                    class="w-full sm:w-[90%] lg:w-[80%] h-[52px] text-[16px] rounded-full border-2 border-[#E6007E] text-[#E6007E] hover:bg-[#E6007E] hover:text-white font-medium transition-colors duration-200"
                                    @click="onSignInClick"
                                >
                                    Sign In
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Single Grey cloud at bottom - mobile only -->
                <div class="lg:hidden absolute bottom-[22%] left-0 w-full pointer-events-none">
                    <!-- Bottom cloud -->
                    <img
                        :src="require('static/assets/images/misc/clouds-grey.svg')"
                        alt=""
                        class="absolute left-[10%] w-[90px] sm:w-[120px] md:w-[160px] opacity-70 pointer-events-none"
                    >
                </div>

                <!-- Footer with Social Links - Filtered to only Instagram and TikTok -->
                <div class="py-6 sm:py-8 lg:py-10 relative">
                    <div class="flex justify-center items-center space-x-6 sm:space-x-8">
                        <a
                            v-for="(social, index) in filteredSocials"
                            :key="index"
                            :href="social.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-[#9CA3AF] hover:text-[#E6007E] transition-colors duration-200"
                        >
                            <i
                                :class="[ social.icon ]"
                                class="text-xl sm:text-2xl"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Components -->
        <Modal
            v-model:isShown="isSignInShown"
            :title="signInTitle"
            :is-close-shown="steps.indexOf(currentStep[selectedMode]) === 0"
            :is-back-shown="isModalBackShown"
            :step-percentage="stepPercentage"
            @close="onSignInClose"
            @back="prevStep"
        >
            <transition name="fade">
                <EnterMobileNumberForm
                    v-if="currentStep[selectedMode] === 'enterMobileNumber'"
                    @success="nextStep"
                />
            </transition>
            <transition name="fade">
                <OtpForm
                    v-if="currentStep[selectedMode] === 'otp'"
                    :mode="selectedMode"
                    @completeProfile="nextStep"
                    @finish="onFinish"
                    @back="prevStep"
                />
            </transition>
            <transition name="fade">
                <ProfileForm
                    v-if="currentStep[selectedMode] === 'profileForm'"
                    @success="nextStep"
                />
            </transition>
        </Modal>
    </GuestLayout>
</template>

<script setup>
    import { computed, reactive, ref } from '@vue/reactivity';
    import { useMeta } from 'vue-meta';

    import router from 'root-js/routes.js';

    import usePreloadRoute from 'root-js/plugins/VuePreloader/composables/usePreloadRoute';

    import GuestLayout from 'root-js/layouts/GuestLayout.vue';

    import Modal from 'root-js/components/common/Modal.vue';
    import EnterMobileNumberForm from 'root-js/components/welcome/EnterMobileNumberForm.vue';
    import SelectUnitForm from 'root-js/components/welcome/SelectUnitForm.vue';
    import PartnerCodeForm from 'root-js/components/welcome/PartnerCodeForm.vue';
    import ProfileForm from 'root-js/components/welcome/ProfileForm.vue';
    import OtpForm from 'root-js/components/welcome/OtpForm.vue';
    import { SOCIALS } from 'root-js/constants/temp.const.js';
    import { useGtag } from 'vue-gtag-next';

    useMeta({ title: 'Space / Welcome' });

    // Preload the route
    usePreloadRoute();
    const { event } = useGtag();

    const stepsPerMode = {
        create: ['enterMobileNumber', 'otp', 'profileForm'],
        signin: ['enterMobileNumber', 'otp', 'profileForm']
    };

    const selectedMode = ref('create');
    const currentStep = reactive({
        create: 'enterMobileNumber',
        signin: 'enterMobileNumber'
    });

    // Only display Instagram and TikTok social links
    const filteredSocials = computed(() => SOCIALS.filter(social => social.icon.includes('instagram') || social.icon.includes('tiktok')));

    const steps = computed(() => stepsPerMode[selectedMode.value]);

    const stepPercentage = computed(() => {
        if (selectedMode.value !== 'create') {
            return false;
        }

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);
        return (indexOfCurrentStep + 1) / steps.value.length * 100;
    });

    const isModalBackShown = computed(() => steps.value.indexOf(currentStep[selectedMode.value]) > 0
        && currentStep[selectedMode.value] !== 'profileForm');

    const isModalShown = ref(false);
    const isPartnerCodeSkipped = ref(false);
    const isSignInShown = ref(false);
    const transition = ref('slide');

    const signInTitle = computed(() => selectedMode.value === 'create' ? 'Create Your Account' : 'Sign In');

    function onCreateAccountClick() {
        isSignInShown.value = true;
        selectedMode.value = 'create';

        // Send GA event for clicking "Get Started"
        event('get_started_button_clicked');
    }

    function onSignInClick() {
        selectedMode.value = 'signin';
        isSignInShown.value = true;

        // Send GA event for clicking "Sign In"
        event('sign_in_button_clicked');
    }

    function onSignInClose() {
        isSignInShown.value = false;
    }

    function onFinish() {
        isSignInShown.value = false;
        router.push({ name: 'Your Space' });
    }

    function onSkipPartnerCode() {
        transition.value = 'slide';

        isPartnerCodeSkipped.value = true;
        currentStep[selectedMode.value] = 'enterMobileNumber';
    }

    function nextStep() {
        transition.value = 'slide';

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);

        if (indexOfCurrentStep + 1 >= steps.value.length) {
            isSignInShown.value = false;
            router.push({ name: 'Your Space' });
            return;
        }

        currentStep[selectedMode.value] = steps.value[indexOfCurrentStep + 1];
    }

    function prevStep() {
        transition.value = 'slide-back';

        const indexOfCurrentStep = steps.value.indexOf(currentStep[selectedMode.value]);

        if (
            isPartnerCodeSkipped.value
            && steps.value[indexOfCurrentStep - 1] === 'selectUnit'
        ) {
            currentStep[selectedMode.value] = 'partnerCode';
            return;
        }

        currentStep[selectedMode.value] = steps.value[indexOfCurrentStep - 1];
    }
</script>
